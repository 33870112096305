import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button, Box, Typography } from "@mui/material";
import ListEditor from "../components/ListEditor";
import fetchWithAuth from "../utils/fetchWithAuth";

function AdminCourseAdd() {
    const [course, setCourse] = useState({
        title: "",
        description: "",
        link: "",
        telegramLink: "",
        duration: "",
        location: "",
        contents: "",
        date: "",
        price: "",
        seats: "",
        courseContent: [],
        priceInclusions: [],
    }); // Начальное состояние для нового курса
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourse((prevCourse) => ({
            ...prevCourse,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);

        fetchWithAuth(`/api/admin/courses/add`, {
            method: "POST",
            body: JSON.stringify(course),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to create course");
                }
                return response.json();
            })
            .then(() => navigate("/admin/courses")) // Перенаправляем на список курсов после успешного сохранения
            .catch((error) => console.error("Не удалось создать курс:", error))
            .finally(() => setIsSaving(false));
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant="h4" gutterBottom>
                Add New Course
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name="title"
                    value={course.title}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    name="description"
                    value={course.description}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Telegram Link"
                    variant="outlined"
                    fullWidth
                    name="telegramLink"
                    value={course.telegramLink}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Duration"
                    variant="outlined"
                    fullWidth
                    name="duration"
                    value={course.duration}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    name="location"
                    value={course.location}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Contents"
                    variant="outlined"
                    fullWidth
                    name="contents"
                    value={course.contents}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Date"
                    variant="outlined"
                    fullWidth
                    name="date"
                    value={course.date}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Price"
                    variant="outlined"
                    fullWidth
                    name="price"
                    value={course.price}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Seats"
                    variant="outlined"
                    fullWidth
                    name="seats"
                    type="number"
                    value={course.seats}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />

                <ListEditor
                    label="Course Content"
                    items={course.courseContent}
                    setItems={(newItems) =>
                        setCourse((prev) => ({ ...prev, courseContent: newItems }))
                    }
                />
                <ListEditor
                    label="Price Inclusions"
                    items={course.priceInclusions}
                    setItems={(newItems) =>
                        setCourse((prev) => ({ ...prev, priceInclusions: newItems }))
                    }
                />

                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSaving}
                    >
                        {isSaving ? "Saving..." : "Add Course"}
                    </Button>
                </Box>
            </form>
        </Container>
    );
}

export default AdminCourseAdd;
