import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';  // Для получения параметра ID из URL
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Подключаем функцию перевода
import EnrollmentForm from './EnrollmentForm';
import SeatsAvailability from './components/SeatsAvailability';

function CourseDetail() {
    const { id } = useParams();  // Получаем id курса из URL
    const { t } = useTranslation(); // Получаем функцию перевода

    const [courseDetails, setCourseDetails] = useState(null);
    const [confirmation, setConfirmation] = useState(false);

    useEffect(() => {
        // Делает запрос на сервер для получения курса по ID
        fetch(`/api/courses/${id}`)
            .then(response => response.json())
            .then(data => setCourseDetails(data))
            .catch(error => console.error('Error fetching course details:', error));
    }, [id]);

    if (!courseDetails) {
        return <Typography variant="h6">{t('loading')}</Typography>;
    }

    const handleEnrollmentConfirm = (updatedCourse) => {
        setConfirmation(true);
        setCourseDetails(updatedCourse);
        localStorage.setItem('courseDetails', JSON.stringify(updatedCourse));
    };

    const areSeatsAvailable =
        courseDetails.availableSeats && courseDetails.availableSeats > 0;

    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                {courseDetails.title}
            </Typography>

            <Box
                display="flex"
                flexDirection={{ xs: 'column', md: 'row' }}
                gap={2}
                alignItems="center"
                marginBottom="20px"
            >
                <Box flex="1">
                    {/* Seats Availability Component */}
                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('courseDate')}:</strong> {courseDetails.date || t('dateToBeAnnounced')}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('location')}:</strong> {courseDetails.location || t('onlineOnSite')}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        <strong>{t('duration')}:</strong> {courseDetails.duration || t('flexible')}
                    </Typography>

                    <Box marginTop="20px">
                        <Typography variant="h5" gutterBottom>
                            {t('coursePrice')}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{courseDetails.price || t('priceToBeAnnounced')}</strong>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {courseDetails.priceInclusions
                                ? t('priceIncludes')
                                : t('priceDetailsComingSoon')}
                        </Typography>
                        <ul style={{ paddingLeft: '20px' }}>
                            {courseDetails.priceInclusions &&
                                courseDetails.priceInclusions.map((item, index) => (
                                    <li key={index}>
                                        <Typography variant="body2">{item}</Typography>
                                    </li>
                                ))}
                        </ul>
                    </Box>

                    <Box marginTop="20px">
                        <Typography variant="h5" gutterBottom>
                            {t('courseContent')}
                        </Typography>
                        {courseDetails.courseContent ? (
                            <ul style={{ paddingLeft: '20px' }}>
                                {courseDetails.courseContent.map((topic, index) => (
                                    <li key={index}>
                                        <Typography variant="body1">{topic}</Typography>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <Typography variant="body1">{t('contentComingSoon')}</Typography>
                        )}
                    </Box>
                </Box>

                <Box flex="1" textAlign="center">
                    <img
                        src={courseDetails.imageSrc}
                        alt={courseDetails.title}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '300px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            marginBottom: '8px',
                        }}
                    />
                    <SeatsAvailability
                        totalSeats={courseDetails.seats}
                        occupiedSeats={courseDetails.occupiedSeats}
                    />
                </Box>
            </Box>

            {confirmation ? (
                <Box textAlign="center" marginTop="40px">
                    <Typography variant="h6">{t('thankYouForEnrolment')}</Typography>
                    <Typography variant="body1">
                        {t('successfulEnrollment')}
                    </Typography>
                    <Typography variant="body1">
                        {t('joinTelegram')}:{' '}
                        <a href={courseDetails.telegramLink} target="_blank" rel="noopener noreferrer">
                            {t('goToTelegram')}
                        </a>
                    </Typography>
                </Box>
            ) : areSeatsAvailable ? (
                <EnrollmentForm courseDetails={courseDetails} onConfirm={handleEnrollmentConfirm} />
            ) : (
                <Box textAlign="center" marginTop="40px">
                    <Typography variant="h6" color="error">
                        {t('noSeatsAvailable')}
                    </Typography>
                    <Typography variant="body1">
                        {t('contactForMoreDetails')}
                    </Typography>
                </Box>
            )}
        </Container>
    );
}

export default CourseDetail;
