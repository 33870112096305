import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, TextField, Button, Box, CircularProgress, Typography } from "@mui/material";
import ListEditor from "../components/ListEditor"; // Компонент для редактирования списков
import fetchWithAuth from "../utils/fetchWithAuth"; // Импорт утилиты

function AdminCourseEdit() {
    const [course, setCourse] = useState(null); // Хранение данных о курсе
    const [loading, setLoading] = useState(true); // Для отображения загрузки данных
    const [isSaving, setIsSaving] = useState(false); // Для отслеживания сохранения изменений
    const { id } = useParams(); // Получаем ID курса из URL
    const navigate = useNavigate();

    useEffect(() => {
        // Запрос к серверу для получения данных о курсе
        fetchWithAuth(`/api/courses/${id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch course");
                }
                return response.json();
            })
            .then((data) => {
                setCourse(data); // Заполняем форму данными курса
            })
            .catch((error) => console.error("Не удалось загрузить курс:", error))
            .finally(() => setLoading(false));
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCourse((prevCourse) => ({
            ...prevCourse,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSaving(true);

        // Отправляем запрос на обновление курса
        fetchWithAuth(`/api/admin/courses/${id}`, {
            method: "PUT",
            body: JSON.stringify(course),
            headers: {
                "Content-Type": "application/json", // Убедитесь, что указали правильный Content-Type
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to save course");
                }
                return response.json();
            })
            .then(() => navigate("/admin/courses")) // Перенаправляем на список курсов после успешного сохранения
            .catch((error) => console.error("Не удалось сохранить курс:", error))
            .finally(() => setIsSaving(false));
    };

    // Если данные курса еще загружаются
    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    if (!course) {
        return (
            <Typography variant="h6" color="text.secondary" sx={{ textAlign: "center", marginTop: 4 }}>
                Курс не найден
            </Typography>
        );
    }

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant="h4" gutterBottom>
                Edit Course
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name="title"
                    value={course.title || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Image URL"
                    variant="outlined"
                    fullWidth
                    name="imageSrc"
                    value={course.imageSrc || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Description"
                    variant="outlined"
                    fullWidth
                    name="description"
                    value={course.description || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Link"
                    variant="outlined"
                    fullWidth
                    name="link"
                    value={course.link || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                    disabled
                />
                <TextField
                    label="Telegram Link"
                    variant="outlined"
                    fullWidth
                    name="telegramLink"
                    value={course.telegramLink || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Duration"
                    variant="outlined"
                    fullWidth
                    name="duration"
                    value={course.duration || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    name="location"
                    value={course.location || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Contents"
                    variant="outlined"
                    fullWidth
                    name="contents"
                    value={course.contents || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Date"
                    variant="outlined"
                    fullWidth
                    name="date"
                    value={course.date || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />
                <TextField
                    label="Price"
                    variant="outlined"
                    fullWidth
                    name="price"
                    value={course.price || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />

                {/* Поле для количества мест */}
                <TextField
                    label="Seats"
                    variant="outlined"
                    fullWidth
                    name="seats"
                    type="number"  // Указываем, что это поле для ввода числовых значений
                    value={course.seats || ""}
                    onChange={handleChange}
                    sx={{ marginBottom: 2 }}
                />

                {/* Редактирование списков */}
                <ListEditor
                    label="Course Content"
                    items={course.courseContent || []}
                    setItems={(newItems) =>
                        setCourse((prev) => ({ ...prev, courseContent: newItems }))
                    }
                />
                <ListEditor
                    label="Price Inclusions"
                    items={course.priceInclusions || []}
                    setItems={(newItems) =>
                        setCourse((prev) => ({ ...prev, priceInclusions: newItems }))
                    }
                />

                {/* Кнопка для сохранения изменений */}
                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSaving}
                    >
                        {isSaving ? "Saving..." : "Save Changes"}
                    </Button>
                </Box>
            </form>
        </Container>
    );
}

export default AdminCourseEdit;
