import React, { useState } from 'react';
import { TextField, Button, Rating, Box, Typography } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Импортируем функцию перевода

function ReviewForm({ onReviewSubmit }) {
    const { t } = useTranslation(); // Получаем функцию перевода
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);
    const maxCommentLength = 1500;

    const handleSubmit = async (e) => {
        e.preventDefault();

        const reviewData = {
            name,
            comment,
            rating: parseFloat(rating.toFixed(1))
        };

        try {
            await axios.post('/api/reviews', reviewData);
            onReviewSubmit(reviewData);
            setName('');
            setComment('');
            setRating(0);
        } catch (error) {
            console.error('Error submitting review:', error);
            alert(t('reviewError')); // Используем перевод для ошибки
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ marginBottom: 3 }}>
            <TextField
                label={t('yourName')} // Перевод для лейбла
                variant="outlined"
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ marginBottom: 2 }}
                required
            />
            <TextField
                label={t('comment')} // Перевод для лейбла
                variant="outlined"
                fullWidth
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                multiline
                rows={4}
                sx={{ marginBottom: 2 }}
                required
            />
            {/* Индикатор количества символов */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
                <Typography variant="body2" color="textSecondary">
                    {comment.length} / {maxCommentLength} {t('characters')}
                </Typography>
                <Typography variant="body2" color={comment.length > maxCommentLength ? 'error' : 'textSecondary'}>
                    {maxCommentLength - comment.length} {t('charactersRemaining')}
                </Typography>
            </Box>
            <Box sx={{ marginBottom: 2 }}>
                <Rating
                    value={rating}
                    onChange={(e, newValue) => setRating(newValue)}
                    size="large"
                />
            </Box>
            <Button type="submit" variant="contained" color="primary" disabled={comment.length > maxCommentLength}>
                {t('submitReview')} {/* Перевод для кнопки */}
            </Button>
        </Box>
    );
}

export default ReviewForm;
