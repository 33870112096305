import React from "react";
import { Typography, Container } from "@mui/material";

function Footer() {
    return (
        <footer>
            <Container>
                <Typography variant="body2">
                    © 2024 Freediving Flow. All rights reserved.
                </Typography>
            </Container>
        </footer>
    );
}

export default Footer;
