import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Login() {
    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("/api/auth/login", credentials);
            localStorage.setItem("jwtToken", response.data); // Сохраняем токен
            navigate("/"); // Переход на главную страницу после входа
        } catch (error) {
            alert("Invalid username or password");
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2>Login</h2>
            <input name="username" placeholder="Username" value={credentials.username} onChange={handleChange} />
            <input name="password" type="password" placeholder="Password" value={credentials.password} onChange={handleChange} />
            <button type="submit">Login</button>
        </form>
    );
}

export default Login;
