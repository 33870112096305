import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import CourseDetail from "./CourseDetail";
import About from "./About";
import Contact from "./Contact";

import ProtectedRoute from "./cms/ProtectedRoute";
import AdminCourseList from "./cms/AdminCourseList";
import AdminCourseEdit from "./cms/AdminCourseEdit";
import AdminCourseAdd from "./cms/AdminCourseAdd";
import AdminBookingManager from "./cms/AdminBookingManager";
import Login from "./cms/Login";
import Logout from "./cms/Logout";
import ReviewsPage from "./ReviewsPage";

function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/course/:id" element={<CourseDetail />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/reviews" element={<ReviewsPage />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/logout" element={<Logout />} />

            {/* Административные маршруты */}
            <Route
                path="/admin/courses"
                element={
                    <ProtectedRoute>
                        <AdminCourseList />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin/course/:id"
                element={
                    <ProtectedRoute>
                        <AdminCourseEdit />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin/course/new"
                element={
                    <ProtectedRoute>
                        <AdminCourseAdd />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/admin/bookings/:id"
                element={
                    <ProtectedRoute>
                        <AdminBookingManager />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
}

export default AppRoutes;