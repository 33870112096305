import React from "react";
import { Typography, Container } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CourseList from "./CoursesList.js";
import { useTranslation } from "react-i18next"; // Импортируйте хук

const theme = createTheme({
    palette: {
        primary: {
            main: "#1976d2", // Основной цвет
        },
        secondary: {
            main: "#ff9800", // Вторичный цвет
        },
    },
    typography: {
        fontFamily: "Roboto, Arial",
        h2: {
            fontWeight: 700,
        },
        body1: {
            fontSize: "1.2rem",
        },
    },
});

function App() {
    const { t } = useTranslation(); // Используем хук для перевода

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg" sx={{ marginTop: "2rem" }}>
                <Typography variant="h2" align="center" gutterBottom>
                    {t('freedivingCourses')}
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary" paragraph>
                    {t('exploreUnderwater')}
                </Typography>
                <CourseList />
            </Container>
        </ThemeProvider>
    );
}

export default App;
