import React from "react";
import { TextField, Button, Box, Typography } from "@mui/material";

function ListEditor({ label, items, setItems }) {
    const handleAddItem = () => {
        setItems([...items, ""]);
    };

    const handleChangeItem = (index, value) => {
        const newItems = [...items];
        newItems[index] = value;
        setItems(newItems);
    };

    const handleRemoveItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        setItems(newItems);
    };

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h6">{label}</Typography>
            {items.map((item, index) => (
                <Box key={index} sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={item}
                        onChange={(e) => handleChangeItem(index, e.target.value)}
                        sx={{ marginRight: 1 }}
                    />
                    <Button variant="contained" color="error" onClick={() => handleRemoveItem(index)}>
                        Remove
                    </Button>
                </Box>
            ))}
            <Button variant="outlined" onClick={handleAddItem}>
                Add {label.slice(0, -1)}
            </Button>
        </Box>
    );
}

export default ListEditor;
