// Утилита для выполнения защищённых запросов
const fetchWithAuth = (url, options = {}) => {
    const token = localStorage.getItem("jwtToken"); // Предполагается, что токен сохранён в localStorage
    if (!token) {
        return Promise.reject(new Error("No auth token found"));
    }

    return fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });
};

export default fetchWithAuth;
