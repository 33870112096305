import React, { useState } from "react";
import { Container, Typography, TextField, Button, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

function Contact() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("/api/email/send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: formData.name,
                    email: formData.email,
                    message: formData.message,
                }),
            });

            if (response.ok) {
                setIsSubmitted(true);
            } else {
                const errorMessage = await response.text();
                console.error("Error sending email:", errorMessage);
                alert(t("errorOccurred"));
            }
        } catch (error) {
            console.error("Error:", error);
            alert(t("errorOccurred"));
        }
    };

    return (
        <Container maxWidth="md" style={{ marginTop: "40px" }}>
            {!isSubmitted ? (
                <>
                    <Typography variant="h4" gutterBottom align="center">
                        {t('contactMe')}
                    </Typography>

                    <form onSubmit={handleSubmit}>
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <TextField
                                label={t('name')}
                                variant="outlined"
                                fullWidth
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                                style={{ marginBottom: "20px", maxWidth: "500px" }}
                            />
                            <TextField
                                label={t('email')}
                                variant="outlined"
                                fullWidth
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                style={{ marginBottom: "20px", maxWidth: "500px" }}
                            />
                            <TextField
                                label={t('message')}
                                variant="outlined"
                                fullWidth
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                multiline
                                rows={4}
                                style={{ marginBottom: "20px", maxWidth: "500px" }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                style={{ maxWidth: "500px" }}
                            >
                                {t('sendMessageButton')}
                            </Button>
                        </Box>
                    </form>
                </>
            ) : (
                <Box textAlign="center" marginTop="40px">
                    <Typography variant="h4">{t('thankYou')}</Typography>
                    <Typography variant="body1">{t('messageSent')}</Typography>
                </Box>
            )}
        </Container>
    );
}

export default Contact;
