import React from "react";
import { Link } from "react-router-dom";
import { Button, Typography, Box } from "@mui/material";
import { useTranslation } from 'react-i18next'; // Подключаем функцию перевода
import './CourseCard.css'; // Импортируем стили
import SeatsAvailability from './components/SeatsAvailability'; // Импортируем новый компонент

function CourseCard({ course }) {
    const { t } = useTranslation(); // Получаем функцию перевода

    return (
        <div className="course-card">
            <div className="course-image-container">
                <img src={course.imageSrc} alt={course.title} className="course-image" />
                <Typography variant="h5" className="course-title">
                    {course.title}
                </Typography>
            </div>

            {/* Добавляем отображение даты */}
            <Box marginBottom={0}>
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('courseDate')}:</strong> {course.date || t('dateToBeAnnounced')}
                </Typography>
            </Box>

            <Box marginBottom={2}>
                <Typography variant="subtitle2" color="textSecondary">
                    <strong>{t('coursePrice')}:</strong> {course.price || t('priceToBeAnnounced')}
                </Typography>
            </Box>

            <Typography variant="body2" color="textSecondary" paragraph>
                {course.description}
            </Typography>

            {/* Добавляем компонент для отображения занятых и доступных мест */}
            <SeatsAvailability
                totalSeats={course.seats}
                occupiedSeats={course.occupiedSeats}
            />

            <Link to={course.link} state={course} style={{ textDecoration: "none" }}>
                <Button variant="contained" color="primary">
                    {t('learnMore')}
                </Button>
            </Link>
        </div>
    );
}

export default CourseCard;
