import React from "react";
import { Container, Typography, Box, Avatar } from "@mui/material";
import { useTranslation } from 'react-i18next';

function About() {
    const { t } = useTranslation();

    return (
        <Container maxWidth="md" style={{ marginTop: "40px", textAlign: "center" }}>
            {/* Ваше фото */}
            <Avatar
                alt="Aleksei"
                src='/api/images/me_lq.png'
                sx={{
                    width: 150,
                    height: 150,
                    margin: "0 auto",
                    marginBottom: "20px",
                    objectPosition: "top"
                }}
            />

            {/* Ваше имя */}
            <Typography variant="h4" gutterBottom>
                {t('about.title')}
            </Typography>

            {/* Описание идеи и эволюции школы */}
            <Box marginBottom="20px">
                <Typography variant="body1" paragraph>
                    {t('about.introduction')}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t('about.original_idea')}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t('about.evolution')}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t('about.school_emphasis')}
                </Typography>
            </Box>

            {/* Миссия и вдохновение */}
            <Box marginTop="40px">
                <Typography variant="h5" gutterBottom>
                    {t('about.mission_title')}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t('about.mission')}
                </Typography>
            </Box>

            {/* Сертификации и профессиональные достижения */}
            <Box marginTop="40px">
                <Typography variant="h5" gutterBottom>
                    {t('about.certifications_title')}
                </Typography>
                <Typography variant="body1" paragraph>
                    {t('about.certifications')}
                </Typography>

                {/* Логотипы ICF, Apnea Academy и SSI */}
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="20px">
                    <Box marginRight="30px" sx={{ maxWidth: '200px', width: '100%' }}>
                        <img
                            src={require('./assets/images/apnea-academy-logo.png')}
                            alt="Apnea Academy Logo"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                    <Box marginRight="30px" sx={{ maxWidth: '120px', width: '100%' }}>
                        <img
                            src={require('./assets/images/icf-logo.png')}
                            alt="ICF Logo"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                    <Box sx={{ maxWidth: '150px', width: '100%' }}>
                        <img
                            src={require('./assets/images/ssi-logo.png')}
                            alt="SSI Logo"
                            style={{
                                width: '100%',
                                height: 'auto',
                                objectFit: 'contain',
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default About;
