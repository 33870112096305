import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
    const token = localStorage.getItem("jwtToken");
    const isAuthenticated = !!token;

    return isAuthenticated ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
