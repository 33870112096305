import React from 'react';
import CourseCard from './CourseCard';
import { Grid } from "@mui/material";

function CoursesList() {
    const [courses, setCourses] = React.useState([]);

    React.useEffect(() => {
        fetch('/api/courses/allCourses')
            .then(response => response.json())
            .then(data => setCourses(data));

        localStorage.removeItem('courseDetails');
    }, []);

    return (
        <Grid container spacing={4}>
            {courses.map((course) => (
                <Grid item xs={12} sm={6} md={4} key={course.id}>
                    <CourseCard
                        course={course}
                    />
                </Grid>
            ))}
        </Grid>
    );
}


export default CoursesList;