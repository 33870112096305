import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, CardHeader } from '@mui/material';
import axios from 'axios';
import ReviewForm from './ReviewForm'; // Importing the form for adding reviews
import { useTranslation } from 'react-i18next'; // Для перевода

function ReviewsPage() {
    const { t } = useTranslation(); // Получаем функцию перевода
    const [reviews, setReviews] = useState([]);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await axios.get('/api/reviews');
                setReviews(response.data);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            }
        };
        fetchReviews();
    }, []);

    const handleNewReview = (newReview) => {
        setReviews((prevReviews) => [newReview, ...prevReviews]); // Добавляем новый отзыв в начало
    };

    const renderRating = (rating) => {
        const filledStars = '★'.repeat(rating); // Заполняем звезды
        const emptyStars = '☆'.repeat(5 - rating); // Оставшиеся пустые звезды
        return filledStars + emptyStars; // Объединяем звездочки
    };

    return (
        <Container maxWidth="md" style={{ marginTop: '20px' }}>
            <Typography variant="h4" gutterBottom>
                {t('reviews')} {/* Переводим заголовок */}
            </Typography>

            <ReviewForm onReviewSubmit={handleNewReview} /> {/* Передаем функцию для добавления отзыва */}

            {reviews.length > 0 ? (
                reviews.map((review, index) => (
                    <Card key={index} style={{ marginBottom: '20px' }}>
                        <CardHeader
                            title={review.name}
                            subheader={`${t('rating')}: ${renderRating(review.rating)}`} // Переводим рейтинг
                        />
                        <CardContent>
                            <Typography variant="body1">{review.comment}</Typography>
                        </CardContent>
                    </Card>
                ))
            ) : (
                <Typography variant="body1" color="textSecondary">
                    {t('noReviews')} {/* Переводим текст, если нет отзывов */}
                </Typography>
            )}
        </Container>
    );
}

export default ReviewsPage;