import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Box,
    CircularProgress,
    Switch,
} from "@mui/material";
import fetchWithAuth from "../utils/fetchWithAuth"; // Импорт утилиты
import ConfirmationDialog from "../components/ConfirmationDialog"; // Импорт диалогового окна

function AdminBookingManager() {
    const { id } = useParams(); // ID курса
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedBookingId, setSelectedBookingId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Запрос к серверу для получения участников курса
        fetchWithAuth(`/api/admin/bookings/course/${id}`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to fetch bookings");
                }
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    setBookings(data.sort((a, b) => a.id - b.id)); // Сортировка по ID
                } else {
                    console.error("Получены некорректные данные", data);
                }
            })
            .catch((error) => {
                console.error("Не удалось загрузить участников:", error);
            })
            .finally(() => {
                setLoading(false); // Данные загружены, скрываем индикатор загрузки
            });
    }, [id]);

    const handleDelete = () => {
        if (!selectedBookingId) return;

        fetchWithAuth(`/api/admin/bookings/${selectedBookingId}`, { method: "DELETE" })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Failed to delete booking");
                }
                setBookings(bookings.filter((booking) => booking.id !== selectedBookingId));
            })
            .catch((error) => console.error("Не удалось удалить участника:", error))
            .finally(() => {
                setOpenDialog(false);
                setSelectedBookingId(null);
            });
    };

    const handleOpenDialog = (bookingId) => {
        setSelectedBookingId(bookingId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedBookingId(null);
    };

    const handlePaymentStatusChange = (bookingId, currentStatus) => {
        const updatedBookings = bookings.map((booking) =>
            booking.id === bookingId
                ? { ...booking, paid: !currentStatus }
                : booking
        );
        setBookings(updatedBookings);

        // Отправляем обновление на сервер
        fetchWithAuth(`/api/admin/bookings/${bookingId}/update-payment-status`, {
            method: "PUT",
            body: JSON.stringify(!currentStatus), // Теперь передаем только булевое значение
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Не удалось обновить статус оплаты");
                }
            })
            .catch((error) => {
                console.error("Ошибка при обновлении статуса оплаты:", error);
                // Вернуть статус обратно, если ошибка
                setBookings(bookings);
            });
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
                <Typography variant="h4">Bookings for Course {id}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate(`/admin/course/${id}`)}
                >
                    Back to Course
                </Button>
            </Box>

            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {Array.isArray(bookings) && bookings.length > 0 ? (
                        bookings.map((booking, index) => (
                            <Grid item xs={12} key={booking.id}>
                                <Card sx={{ boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h6" component="div">
                                            {index + 1}. {booking.user?.fullName || "Unknown"} ({booking.user?.email || "No email"})
                                        </Typography>
                                        <Box sx={{ display: "flex", alignItems: "center", marginTop: 1 }}>
                                            <Typography variant="body2" sx={{ marginRight: 1 }}>
                                                Paid:
                                            </Typography>
                                            <Switch
                                                checked={booking.paid || false}
                                                onChange={() => handlePaymentStatusChange(booking.id, booking.paid)}
                                            />
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            color="error"
                                            sx={{ marginTop: 1 }}
                                            onClick={() => handleOpenDialog(booking.id)}
                                        >
                                            Delete
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    ) : (
                        <Typography variant="h6" color="text.secondary">
                            No bookings available
                        </Typography>
                    )}
                </Grid>
            )}

            <ConfirmationDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleDelete}
                title="Confirm Delete"
                message="Are you sure you want to delete this booking? This action cannot be undone."
            />
        </Container>
    );
}

export default AdminBookingManager;
